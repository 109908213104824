import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const SpinnerGlobal = () => {
    return (
        <Box 
            sx={{ 
                display: 'flex',
                width:'100%',
                height:'100vh',
                justifyContent:'center',
                alignItems:'center',
                backgroundImage: "radial-gradient(circle at 50% 50%, #372336 0, #2e1932 16.67%, #240f2e 33.33%, #19002b 50%, #0d0028 66.67%, #000027 83.33%, #000025 100%)",
            }}
        >
            <CircularProgress size={80} color="secondary"/>
        </Box>
    )
}

export default SpinnerGlobal