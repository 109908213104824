import React from 'react'
import { AdvancedImage, lazyload, accessibility, responsive, } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";

const Slide = (props) => {
    const width = window.innerWidth
    // Create and configure your Cloudinary instance.
    const cld = new Cloudinary({
        cloud: {
            cloudName: process.env.REACT_APP_CLOUDINARY_NAME
        }
    })

    // Use the video with public ID, 'docs/walking_talking'.
    const myImage = cld.image(props.poster.public_id);

    return (
        <AdvancedImage
            width={width-15}
            cldImg={myImage}
            style={{position:'absolute'}}
            plugins={[lazyload(), responsive(), accessibility()]}
        />

    )
}

export default Slide