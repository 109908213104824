import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//Layouts
import MainLayout from "../layouts/MainLayout";
import SpinnerGlobal from '../custom/spiner/SpinnerGlobal';

//Views

//Hook
import RequireAuth from "../../hooks/auth/RequireAuth";
import PublicRoutes from "../../hooks/auth/PublicRoutes";
import PublicVideo from '../views/video/PublicVideo';

const Dashboard = lazy(() => import("../views/dashboard/Dashboard"))
const MyAccount = lazy(() => import("../views/myaccount/MyAccount"))
const Price = lazy(() => import("../views/price/Price"))
const Login = lazy(() => import("../views/auth/Login"))
const Register = lazy(() => import("../views/auth/Register"))
const Video = lazy(() => import("../views/video/Video"))
const PaySuccess = lazy(() => import("../views/pays/PaySuccess"))
const PayError = lazy(() => import("../views/pays/PayError"))
const Admin = lazy(() => import("../views/adm/Admin"))
const VideoCreate = lazy(() => import("../views/adm/VideoCreate"))
const VideoCreateCategory = lazy(() => import("../views/adm/VideoCreateCategory"))
const VideoCreateType = lazy(() => import("../views/adm/VideoCreateType"))
const Checkout = lazy(() => import("../views/checkout/Checkout"))
const Support = lazy(() => import("../views/support/Support"))
const RecoveryPassword = lazy(() => import("../views/auth/RecoveryPassword"))


const RootRouter = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <MainLayout><Dashboard /></MainLayout>
                </Suspense>
            ),
        },
        {
            path: "/my-account",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><MyAccount /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/price",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <MainLayout><Price /></MainLayout>
                </Suspense>
            ),
        },
        {
            path: "/login",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <PublicRoutes><MainLayout><Login /></MainLayout></PublicRoutes>
                </Suspense>
                // <MainLayout><Login /></MainLayout>
            ),
        },
        {
            path: "/register",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <PublicRoutes><MainLayout><Register /></MainLayout></PublicRoutes>
                </Suspense>
            ),
        },
        {
            path: "/recovery-password/:token",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <PublicRoutes><MainLayout><RecoveryPassword /></MainLayout></PublicRoutes>
                </Suspense>
            ),
        },
        {
            path: "/video/:id",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><Video /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/pay/success",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><PaySuccess /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/pay/error",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><PayError /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/admin",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><Admin /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/admin/video/create",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><VideoCreate /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/admin/video/create/category",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><VideoCreateCategory /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/admin/video/create/type",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><VideoCreateType /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/checkout/suscribe",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <RequireAuth><MainLayout><Checkout /></MainLayout></RequireAuth>
                </Suspense>
            ),
        },
        {
            path: "/support",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <MainLayout><Support /></MainLayout>
                </Suspense>
                // <MainLayout><Login /></MainLayout>
            ),
        },
        {
            path: "/video/title/:title",
            element: (
                <Suspense fallback={<SpinnerGlobal />}>
                    <MainLayout><PublicVideo /></MainLayout>
                </Suspense>
            ),
        },
    ]);

    return (
        <RouterProvider router={router} />
    )
}

export default RootRouter;