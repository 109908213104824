import React, { useEffect, useRef } from 'react';

function VideoPlayer({video, height, width}) {
    const cloudinaryRef = useRef();
    const videoRef = useRef();

    useEffect(() => {
        if (cloudinaryRef.current) return;
        cloudinaryRef.current = window.cloudinary;
        cloudinaryRef.current.videoPlayer(videoRef.current, {
            cloud_name: process.env.REACT_APP_CLOUDINARY_NAME,
        });
    }, []);

    return <video
        ref={videoRef}
        data-cld-public-id={video}
        height={height - 90}
        width={width}
        controls
        muted
        // className="cld-video-player cld-fluid"
        className="cld-video-player"
        />;
}

export default VideoPlayer;