import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import { Alert, Box, Typography } from '@mui/material';
import DataVideoService from '../../../services/DataVideoService';

const WatchMoreVideo = ({ OpenModalPreviewData }) => {

    const width = window.innerWidth

    const [freeVideos, setFreeVideos] = React.useState([])
    const [errors, seterrors] = React.useState({})

    React.useEffect(() => {
        const getFreeVideos = async () => {
            try {
                const getFreeVideos = await DataVideoService.getFreeDataVideo()
                // console.log(getFreeVideos)
                setFreeVideos(getFreeVideos)
            } catch (error) {
                console.log(error)
                seterrors({ formError: "Has an error to fetch data" })
            }
        }

        getFreeVideos()
    }, [])

    return (
        <>
            <Box>
                {
                    errors.formError && (
                        <Alert severity='error'> {errors.formError && errors.formError}</Alert>
                    )
                }
            </Box>

            {
                width < 480 && (
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        lazy={"true"}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        slidesPerView={1}
                        spaceBetween={10}
                    >
                        {
                            freeVideos.length > 0 && (
                                freeVideos.map((video, index) => (
                                    <SwiperSlide key={index}>
                                        <Box
                                            onClick={() => OpenModalPreviewData(video)}
                                            sx={{
                                                width: '100%',
                                                height: 480,
                                                position: 'relative',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <Box
                                                component={'img'}
                                                alt={video.title}
                                                src={video.poster.secure_url}
                                                loading="lazy"
                                                sx={{
                                                    position: "absolute",
                                                    left: "50%",
                                                    top: "50%",
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: 500, backgroundColor: 'rgba(0,0,0,0.5)', flexDirection: 'column', cursor: 'pointer', transition: '0.5s', '&:hover': { backgroundColor: 'rgba(0,0,0,0.6)' }
                                                }}
                                            >
                                                <Typography sx={{ color: 'white', fontSize: 22, fontFamily: "'Roboto' sans-serif", fontWeight: '500', textAlign: 'center' }}>{video.title}</Typography>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    {
                                                        video.categories.map((cat, index) => (
                                                            <Typography key={index} sx={{ color: 'white', fontSize: 14, fontFamily: "'Roboto' sans-serif", fontWeight: '300', textAlign: 'left', mb: 3, ml: 1 }}>{cat}</Typography>
                                                        ))
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>

                                    </SwiperSlide>
                                ))
                            )
                        }

                    </Swiper>
                )
            }

            {
                width > 480 && width < 768 && (
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        lazy={"true"}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        slidesPerView={2}
                        spaceBetween={10}
                    >

                        {
                            freeVideos.length > 0 && (
                                freeVideos.map((video, index) => (
                                    <SwiperSlide key={index}>
                                        <>
                                            <Box
                                                onClick={() => OpenModalPreviewData(video)}
                                                sx={{
                                                    width: '100%',
                                                    height: 480,
                                                    position: 'relative',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                <Box
                                                    component={'img'}
                                                    alt={video.title}
                                                    src={video.poster.secure_url}
                                                    loading="lazy"
                                                    sx={{
                                                        position: "absolute",
                                                        left: "50%",
                                                        top: "50%",
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: 500, backgroundColor: 'rgba(0,0,0,0.5)', flexDirection: 'column', cursor: 'pointer', transition: '0.5s', '&:hover': { backgroundColor: 'rgba(0,0,0,0.6)' }
                                                    }}
                                                >
                                                    <Typography sx={{ color: 'white', fontSize: 22, fontFamily: "'Roboto' sans-serif", fontWeight: '500', textAlign: 'center' }}>{video.title}</Typography>
                                                    <Box>
                                                        {
                                                            video.categories.map((cat, index) => (
                                                                <Typography key={index} sx={{ color: 'white', fontSize: 14, fontFamily: "'Roboto' sans-serif", fontWeight: '300', textAlign: 'left', mb: 3 }}>{cat}</Typography>
                                                            ))
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                        </>
                                    </SwiperSlide>
                                ))
                            )
                        }

                    </Swiper>
                )
            }

            {
                width > 768 && (
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        lazy={"true"}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        slidesPerView={3}
                        spaceBetween={15}
                    >

                        {
                            freeVideos.length > 0 && (
                                freeVideos.map((video, index) => (
                                    <SwiperSlide key={index}>
                                        <>
                                            <Box
                                                onClick={() => OpenModalPreviewData(video)}
                                                sx={{
                                                    width: '100%',
                                                    height: 480,
                                                    position: 'relative',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                <Box
                                                    component={'img'}
                                                    alt={video.title}
                                                    src={video.poster.secure_url}
                                                    loading="lazy"
                                                    sx={{
                                                        position: "absolute",
                                                        left: "50%",
                                                        top: "50%",
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: 500, backgroundColor: 'rgba(0,0,0,0.5)', flexDirection: 'column', cursor: 'pointer', transition: '0.5s', '&:hover': { backgroundColor: 'rgba(0,0,0,0.6)' }
                                                    }}
                                                >
                                                    <Typography sx={{ color: 'white', fontSize: 22, fontFamily: "'Roboto' sans-serif", fontWeight: '500', textAlign: 'center' }}>{video.title}</Typography>
                                                    <Box>
                                                        {
                                                            video.categories.map((cat, index) => (
                                                                <Typography key={index} sx={{ color: 'white', fontSize: 14, fontFamily: "'Roboto' sans-serif", fontWeight: '300', textAlign: 'left', mb: 3 }}>{cat}</Typography>
                                                            ))
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                        </>
                                    </SwiperSlide>
                                ))
                            )
                        }

                    </Swiper>
                )
            }

        </>
    )
}

export default WatchMoreVideo