import React from 'react'
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';

const Footer = (props) => {
  return (
    <Box sx={{mb:3}}>
      <Typography variant="body2" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
          Jenny Taborda
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" align="center" {...props}>Versión 1.0.0</Typography>
    </Box>
  )
}

export default Footer