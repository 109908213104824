import React from 'react'
import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from "@cloudinary/url-gen";

const Slide = () => {
    const width = window.innerWidth
    // Create and configure your Cloudinary instance.
    const cld = new Cloudinary({
        cloud: {
            cloudName: 'dep7wr2dv'
        }
    })

    // Use the video with public ID, 'docs/walking_talking'.
    const myVideo = cld.video('PublicDataPage/TRAILER_BANNER_yjynzf');

    return (
        <React.Fragment>
            {
                width < 480 && (
                    <></>
                    // <AdvancedVideo
                    //     width={width}
                    //     cldVid={myVideo}
                    //     poster={"https://res.cloudinary.com/dep7wr2dv/image/upload/v1703629840/PublicDataPage/PREVISUALIZACION_irhpzi.jpg"}
                    //     style={{ position: 'absolute' }}
                    // />
                )
            }

            {
                width > 481 && width < 767 && (
                    <AdvancedVideo
                        width={width}
                        cldVid={myVideo}
                        poster={"https://res.cloudinary.com/dep7wr2dv/image/upload/v1703629840/PublicDataPage/PREVISUALIZACION_irhpzi.jpg"}
                        loop
                        muted
                        preload="auto"
                        autoPlay={true}
                        style={{ position: 'absolute' }}
                    />

                )
            }

            {
                width > 768 && (
                    <AdvancedVideo
                        width={width - 15}
                        cldVid={myVideo}
                        poster={"https://res.cloudinary.com/dep7wr2dv/image/upload/v1703629840/PublicDataPage/PREVISUALIZACION_irhpzi.jpg"}
                        loop
                        muted
                        preload="auto"
                        autoPlay={true}
                        style={{ position: 'absolute' }}
                    />
                )
            }
        </React.Fragment>
    )
}

export default Slide