import React from 'react'
// import { Cloudinary } from "@cloudinary/url-gen";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import VideoPlayer from './VideoPlayer';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '100%', md: '100%' },
    height: { xs: '90vh', sm: '95vh', md: '100vh' },
    backgroundImage: "radial-gradient(circle at 50% 50%, #372336 0, #2e1932 16.67%, #240f2e 33.33%, #19002b 50%, #0d0028 66.67%, #000027 83.33%, #000025 100%)",
    border: '2px solid #000',
    boxShadow: 24,
    pt: 3,
    pr: 3,
    pl: 3,
    mb: 6,
    overflowY: { xs: 'scroll', sm: 'scroll', md: 'hidden' }

};

const WatchVideo = ({ open, setOpen, video, poster }) => {

    const handleClose = () => setOpen(false);

    const width = window.innerWidth
    const height = window.innerHeight

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mb: 3, pr: 1 }}>
                    <Box>
                        <Button
                            color='secondary'
                            sx={{
                                display: { xs: 'block', sm: 'flex' },
                                fontSize: 18,
                                borderRadius: '15%',
                                backgroundColor: '#2e2e2e'
                            }}
                            onClick={() => handleClose()}>X</Button>
                    </Box>
                </Box>

                <Box>

                    <>
                        <VideoPlayer video={video.public_id} height={height} width={width}/>
                        {/* <video
                            id="doc-player"
                            width={width}
                            height={height - 90}
                            poster={poster.secure_url}
                            preload="auto"
                            controls
                            muted
                            class="cld-video-player cld-fluid"
                        ></video> */}
                        {/* <AdvancedVideo
                            controls
                            controllist="nodownload"
                            cldVid={myVideo}
                            width={width}
                            height={height - 180}
                            cldPoster={poster.secure_url}
                            preload="auto"
                            style={{ position: 'absolute' }}
                            ref={videoRef}
                        />

                        <Box>
                            <Button onClick={() => playVideo()}>Play</Button>
                        </Box> */}
                    </>

                </Box>

                {/* {
                    width < 480 && (
                        <video
                            controls
                            controllist="nodownload"
                            width={width}
                            height={height - 90}
                            poster={poster.secure_url}
                            preload="auto"
                        >
                            <source src={myURL} type="video/mp4" />
                        </video>

                        // <AdvancedVideo
                        //     id="componnetVideo768"
                        //     cldVid={myVideo}
                        //     width={width}
                        //     muted
                        //     cldPoster={poster.secure_url}
                        //     preload="auto"
                        //     autoPlay={true}
                        //     controls
                        //     controllist="nodownload"
                        //     style={{ position: 'absolute' }}
                        // />
                    )
                }

                {
                    width > 480 && width < 768 && (

                        <>
                            <video
                                ref={videoRef}
                                width={width}
                                height={height - 90}
                                poster={poster.secure_url}
                                preload="auto"
                            >
                                <source src={myURL} type="video/mp4" />
                            </video>

                            <Box>
                                <Button onClick={playVideo}>Play</Button>
                            </Box>
                        </>


                        // <AdvancedVideo

                        //     id="componnetVideo768"
                        //     cldVid={myVideo}
                        //     width={width}
                        //     muted
                        //     cldPoster={poster.secure_url}
                        //     loop
                        //     preload="auto"
                        //     controls
                        //     controllist="nodownload"
                        //     autoPlay={true}
                        //     style={{ position: 'absolute' }}
                        // />
                    )
                }

                {
                    width > 768 && (
                        <>
                            <AdvancedVideo
                                cldVid={myVideo}
                                width={width}
                                height={height - 180}
                                cldPoster={poster.secure_url}
                                loop
                                preload="auto"
                                style={{ position: 'absolute' }}
                                ref={videoRef}
                            />

                            <Box>
                                <Button onClick={() => playVideo()}>Play</Button>
                            </Box>
                        </>
                    )
                } */}
            </Box>
        </Modal>


    )
}

export default WatchVideo