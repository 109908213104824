// import { useNavigate } from "react-router-dom"
import { createContext, useState } from "react"
// import config from '../../settings/config'


const AuthProvider = ({children}) => {
    const InitialToken = localStorage.getItem('jennytaborda_token')
    const InitialRole = localStorage.getItem('jennytaborda_role')
    const InitialPlan = localStorage.getItem('jennytaborda_plan')
    // let navigate = useNavigate()
    // let location = useLocation()
    const [token, seToken] = useState(InitialToken || null)
    const [role, setRole] = useState(InitialRole || null)
    const [plan, setPlan] = useState(InitialPlan || null)


    const login = (token, role, plan) => {
        seToken(token)
        setRole(role)
        setPlan(plan)
        localStorage.setItem('jennytaborda_token',token)
        localStorage.setItem('jennytaborda_role',role)
        localStorage.setItem('jennytaborda_plan',plan)
    }

    const register = (token, role, plan) => {
        seToken(token)
        setRole(role)
        localStorage.setItem('jennytaborda_token',token)
        localStorage.setItem('jennytaborda_role',role)
        localStorage.setItem('jennytaborda_plan',plan)
    }
    
    const logout = () => {
        localStorage.removeItem("jennytaborda_token")
        localStorage.removeItem("jennytaborda_role")
        localStorage.removeItem('jennytaborda_plan')
        seToken(null)
        setRole(null)
        setPlan(null)
    }

    const contextValue = {
        token,
        role,
        plan,
        login,
        logout,
        register
    }
    return <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
}

export const AuthContext = createContext()
export default AuthProvider