import { useLocation, Navigate } from "react-router-dom"
import useAuth from "./useAuth"
import React from 'react'

const RequireAuth = ({ children }) => {
    const { token, logout } = useAuth()
    let location = useLocation()

    if (!token) {
        logout()
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children
    }
}

export default RequireAuth