import { useLocation, Navigate } from "react-router-dom"
import useAuth from "./useAuth"
import React from 'react'

const PublicRoutes = ({ children }) => {
    const { token } = useAuth()
    let location = useLocation()

    if (token) {
        return <Navigate to="/my-account" state={{ from: location }} replace />;
    } else {
        return children
    }
}

export default PublicRoutes