import React from 'react'
import { Helmet } from "react-helmet";
import Slide from '../../custom/video/Slide'
import SlideTelegram from '../../custom/price/Slide'
import { Alert, Box, Button, Divider, Link, TextField, Typography } from '@mui/material'
import Footer from '../../custom/footer/Footer'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import WatchMoreVideo from '../../custom/video/WatchMoreVideo';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Rating from '../../custom/video/Rating';
import useAuth from '../../../hooks/auth/useAuth'
import VideoService from '../../../services/VideoService'
import { useNavigate, useParams } from 'react-router-dom'
import WatchVideo from '../../custom/video/WatchVideo'
import PreviewDataVideo from '../../custom/myaccount/PreviewDataVideo'

const PublicVideo = () => {
    const navigate = useNavigate()

    const [errors, setErrors] = React.useState({})
    const { token } = useAuth()
    const params = useParams()
    const [video, setVideo] = React.useState(null)

    const [openWatchVideo, setOpenWatchVideo] = React.useState(false)

    const [openModalPreview, setOpenModalPreview] = React.useState(false)
    const [selectedVideo, setSelectedVideo] = React.useState(null)

    const [rating, setRating] = React.useState(0);

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const OpenModalPreviewData = (video) => {
        setSelectedVideo(video)
        setOpenModalPreview(true)
    }

    React.useEffect(() => {
        const getVideo = async () => {
            try {
                const getVideoData = await VideoService.watchVideoFree(params.title, token)
                // console.log(getVideoData)
                setVideo(getVideoData)
            } catch (error) {
                console.log(error)
                setErrors({ serverError: error.message })
            }
        }

        getVideo()
    }, [params.title, token])

    React.useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <Box>
            <Box>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Jenny Taborda Live | Watch our free content</title>
                    <link rel="canonical" href={`https://jennytaborda.live/video/title/${params.title}`} />
                    <meta name="description" content={`Jenny Taborda Live and Jenny Taborda Our content will make you want more, look ${params.title}`} />
                </Helmet>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    height: { xs: '70vh', sm: '100vh', md: '100vh' },
                    position: 'absolute',
                    overflow: 'hidden'
                }}
            >
                {
                    video && <Slide poster={video.poster} />
                }
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    width: '100%',
                    height: { xs: '70vh', sm: '100vh', md: '100vh' },
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end'
                }}
            >
                <Box sx={{ width: '99%' }}>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: { xs: 7, sm: 7, md: 9 } }}>
                        <Box
                            sx={{
                                borderRadius: '50%',
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                border: "solid 15px",
                                cursor: 'pointer'
                            }}
                        >
                            <PlayArrowIcon sx={{ width: 35, height: 35, color: '#000', m: 2 }} onClick={() => setOpenWatchVideo(true)} />
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: { xs: '100%', sm: '60%', md: '60%' },
                            pl: 5,
                            pt: 2,
                            mb: 5
                        }}
                    >
                        <Box>

                            <Typography
                                component='h2'
                                sx={{
                                    color: 'white',
                                    fontSize: { xs: 26, sm: 48, md: 48 },
                                    fontFamily: "'Roboto' sans-serif",
                                    fontWeight: '900',
                                    mb: 2
                                }}
                            >
                                {video?.title && video.title}
                            </Typography>

                            <Typography
                                sx={{
                                    color: '#DEDEDE',
                                    fontSize: { xs: 16, sm: 20, md: 20 },
                                    fontFamily: "'Roboto' sans-serif",
                                    fontWeight: '400'
                                }}
                            >
                                Leave a review | {video?.categories && video.categories.map((cat) => (`${cat} `))}
                            </Typography>

                            <Box sx={{ mt: 3 }}>
                                <Button component="label" variant="contained" startIcon={<PlayArrowIcon />} sx={{ fontSize: 24 }} onClick={() => setOpenWatchVideo(true)}>
                                    PLAY
                                </Button>
                            </Box>
                        </Box>

                    </Box>

                    <Box
                        sx={{ml:5, mr:{xs:5,lg:0}}}
                    >
                            <Typography
                                component='p'
                                sx={{
                                    color: 'white',
                                    fontSize: { xs: 26, sm: 30, md: 30 },
                                    fontFamily: "'Roboto' sans-serif",
                                    fontWeight: '500',
                                    mb: 2,
                                    mt: 3,
                                    border: "solid #fff 3px",
                                    p:2
                                }}
                            >
                                <Link
                                    href="/login"
                                    sx={{
                                        color:"secondary.main"
                                    }}
                                >
                                    Log in for watch full free video
                                </Link>
                                
                            </Typography>
                        </Box>
                </Box>
            </Box>

            <Box>
                {
                    errors.serverError && <Alert severity='error'>{errors.serverError}</Alert>
                }
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', my: 5 }}>
                <Box sx={{ width: '75%', margin: '0 auto' }}>
                    <Typography sx={{ color: 'white', fontSize: 28, fontFamily: "'Roboto' sans-serif", fontWeight: '500', mt: 3, textAlign: 'left', mb: 3 }}>Whatch More Content</Typography>
                    <Divider color="#fff" sx={{ mb: 3 }} />
                </Box>

                <WatchMoreVideo OpenModalPreviewData={OpenModalPreviewData} />

            </Box>

            {
                token && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            my: 5
                        }}
                    >
                        <Box sx={{ width: '75%', margin: '0 auto' }}>
                            <Typography sx={{ color: 'white', fontSize: 20, fontFamily: "'Roboto' sans-serif", fontWeight: '500', mt: 3, textAlign: 'left', mb: 3 }}>Reviews for {video?.title}</Typography>
                            <Divider color="#fff" sx={{ mb: 3 }} />

                            <Typography sx={{ color: 'white', fontSize: 14, fontFamily: "'Roboto' sans-serif", fontWeight: '500', mt: 3, textAlign: 'left', mb: 3 }}>There are currently no reviews for {video?.title}</Typography>

                            <Box sx={{ my: 10 }}>
                                <Box>
                                    <Rating rating={rating} onRatingChange={handleRatingChange} />
                                </Box>

                                <Box sx={{ mt: 2 }}>
                                    <TextField
                                        fullWidth
                                        name='comment'
                                        id='comment'
                                        color='secondary'
                                        label="Write comment"
                                        rows={3}
                                        focused
                                        sx={{
                                            color: 'white'
                                        }}
                                    />
                                </Box>

                                <Box sx={{ mt: 3 }}>
                                    <Button component="label" variant="contained" startIcon={<StarBorderIcon />} sx={{ fontSize: 18 }}>
                                        SUBMIT REVIEW
                                    </Button>
                                </Box>
                            </Box>
                            <Divider color="#fff" sx={{ mb: 3 }} />
                        </Box>
                        {/* <WatchMoreVideo /> */}
                    </Box>
                )
            }

            <Box sx={{ my: 2 }}>
                <Box
                    sx={{
                        width: '100%',
                        height: { xs: '60vh', sm: '70vh', md: '70vh' },
                        position: 'absolute',
                        overflow: 'hidden'
                    }}
                >
                    <SlideTelegram />
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        width: '100%',
                        height: { xs: '60vh', sm: '70vh', md: '78vh' },
                        overflowY: 'hidden',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', sm: '60%', md: '60%' },
                            boxSizing: "content-box",
                            pl: { xs: '8%', sm: '12%', md: '12%' },
                            pr: { xs: '8%', sm: '12%', md: '12%' },
                            pt: { xs: '9%' },
                            // mt: { xs: '9%' },
                            pb: { xs: '10%' }
                        }}
                    >
                        <Typography
                            variant='h2'
                            sx={{
                                color: 'white',
                                fontSize: { xs: 30, sm: 44, md: 44 },
                                fontFamily: "'Roboto' sans-serif",
                                fontWeight: '500',
                                mb: 2
                            }}
                        >
                            Telegram and Platform
                        </Typography>
                        <Typography
                            variant='h2'
                            sx={{
                                color: 'white',
                                fontSize: { xs: 30, sm: 44, md: 44 },
                                fontFamily: "'Roboto' sans-serif",
                                fontWeight: '500'
                            }}
                        >
                            All Week New Content
                        </Typography>
                        <Typography sx={{ color: '#DEDEDE', fontSize: 20, fontFamily: "'Roboto' sans-serif", fontWeight: '400' }}>Connect with me on my telegram channel & Get exclusive discounts on my personalized content.</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Typography sx={{ color: 'white', fontSize: 40, fontFamily: "'Roboto' sans-serif", fontWeight: '500', mt: 3 }}>$28.99</Typography>
                            <Typography sx={{ color: '#DEDEDE', fontSize: 16, fontFamily: "'Roboto' sans-serif", fontWeight: '300', mb: 1, ml: 1 }}>/ per month</Typography>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                            <Button variant="contained" sx={{ fontSize: 24 }} onClick={() => navigate("/checkout/suscribe")}>SUSCRIBE NOW</Button>
                        </Box>

                    </Box>
                </Box>
            </Box>

            <Box>
                <Footer sx={{ color: 'white' }} />
            </Box>

            {
                openWatchVideo && video && (
                    <WatchVideo
                        open={openWatchVideo}
                        setOpen={setOpenWatchVideo}
                        video={video.triller}
                        poster={video.poster}
                    />
                )
            }

            {
                selectedVideo && (
                    <PreviewDataVideo
                        open={openModalPreview}
                        setOpen={setOpenModalPreview}
                        video={selectedVideo}
                    />
                )
            }

        </Box>
    )
}

export default PublicVideo