import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider } from '@mui/material';

//Icons
// import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: '100%', md: '70%' },
    height: { xs: '95vh', sm: '95vh', md: '80vh' },
    backgroundImage: "radial-gradient(circle at 50% 50%, #372336 0, #2e1932 16.67%, #240f2e 33.33%, #19002b 50%, #0d0028 66.67%, #000027 83.33%, #000025 100%)",
    border: '2px solid #000',
    boxShadow: 24,
    p: 6,
    overflowY: { xs: 'scroll', sm: 'scroll', md: 'hidden' }
};

const PreviewDataVideo = ({ open, setOpen, video }) => {
    const navigate = useNavigate()
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ color: 'white', fontSize: 24, fontFamily: "'Roboto' sans-serif", fontWeight: '500', mt: 3, textAlign: 'left', mb: 3 }}
                    >
                        {video.title}
                    </Typography>

                    <Box>
                        <Button
                            color='secondary'
                            sx={{
                                display: { xs: 'block', sm: 'flex' },
                                fontSize: 18,
                                borderRadius: '15%',
                                backgroundColor: '#2e2e2e'
                            }}
                            onClick={() => handleClose()}>X</Button>
                    </Box>

                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'column', md: 'row' }
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: '100%', sm: '40%', md: '40%' },
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                width: '80%',
                                position: 'relative',
                                overflow: 'hidden'
                            }}
                        >
                            <Box sx={{ height: 420 }}>
                                <Box
                                    component={'img'}
                                    alt={video.title}
                                    src={video.poster.secure_url}
                                    loading="lazy"
                                    sx={{
                                        position: 'absolute',
                                        height: 420,
                                        left: { xs: "-85%", sm: "-70%", md: "-70%" },
                                    }}
                                />
                                {/* <Box
                                    sx={{
                                        position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: 470, backgroundColor: 'rgba(0,0,0,0.7)', flexDirection: 'column', cursor: 'pointer', transition: '0.5s', '&:hover': { backgroundColor: 'rgba(0,0,0,0.6)' }
                                    }}
                                >
                                    <LockIcon color='secondary' sx={{width:50, height:50}}/>
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '100%', sm: '60%', md: '60%' },
                            position: 'relative'
                        }}
                    >
                        <Typography sx={{ color: 'white', fontSize: 22, fontFamily: "'Roboto' sans-serif", fontWeight: '300', mt: 3, textAlign: 'left', mb: 1 }}>Categories</Typography>
                        <Divider color={'white'} />
                        <Typography sx={{ color: 'white', fontSize: 16, fontFamily: "'Roboto' sans-serif", fontWeight: '300', mt: 3, textAlign: 'left', mb: 1 }}>{video.categories.map((cat) => (`${cat} `))}</Typography>

                        <Box sx={{ mt: { xs: 5, sm: 23, md: 23 } }}>
                            <Typography sx={{ color: 'white', fontSize: 22, fontFamily: "'Roboto' sans-serif", fontWeight: '300', textAlign: 'left', mb: 1 }}>You can watch this video</Typography>
                        </Box>
                        <Box>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" sx={{ fontSize: 24, width: '100%', '&:hover': { backgroundColor: 'rgba(0,0,0,0.2)' } }} onClick={() => { navigate(`/video/${video._id}`); handleClose() }}>WATCH</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default PreviewDataVideo