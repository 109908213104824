import { Box } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
// import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/auth/useAuth';
// import AdbIcon from '@mui/icons-material/Adb';

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const pages = ['Home', 'Pricing', 'Support'];
const settings = ['Account', 'Logout'];

const MainLayout = (props) => {
    //Validar varaibles de entorno en prd

    const { token, logout } = useAuth()
    const navigate = useNavigate()

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const goRoute = (setting) => {
        switch (setting) {
            case "Home":
                navigate('/')
                break;
            case "Account":
                navigate('/my-account')
                break;
            case "Pricing":
                navigate('/price')
                break;
            case "Logout":
                logout()
                break;
            case "Support":
                navigate('/support')
                break;
            default:
                navigate('/')
        }
    }


    return (
        <Box
            sx={{
                background: (theme) => theme.palette.background.default,
                width: { xs: '100vw', sm: '100vw', md: '99vw' },
                backgroundImage: "radial-gradient(circle at 50% 50%, #372336 0, #2e1932 16.67%, #240f2e 33.33%, #19002b 50%, #0d0028 66.67%, #000027 83.33%, #000025 100%)",
                overflowX: 'hidden'
            }}
        >
            {/* <CssBaseline /> */}

            <HideOnScroll {...props}>

                <AppBar sx={{ width: '100vw', overflowX: 'hidden' }}>
                    <Toolbar>

                        {/* Menu to > 768 */}

                        <Box sx={{ width: '100%', display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', alignItems: 'center' }}>

                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <Box
                                    component={'img'}
                                    src='https://res.cloudinary.com/dep7wr2dv/image/upload/v1703630590/PublicDataPage/jennytaborda_logo_abn9fu.png'
                                    sx={{ height: 30 }}
                                />
                            </Box>

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {pages.map((page) => (
                                    <Button
                                        key={page}
                                        onClick={() => { goRoute(page); handleCloseNavMenu() }}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page}
                                    </Button>
                                ))}
                            </Box>

                            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                                <Box
                                    sx={{ mr: 3, cursor: 'pointer' }}
                                    onClick={() => navigate('/login')}
                                >
                                    {
                                        !token && <Typography>LOGIN</Typography>
                                    }

                                </Box>

                                {
                                    token && (
                                        <>
                                            <Tooltip title="Open settings">
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                sx={{ mt: '45px' }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                            >
                                                {settings.map((setting) => (
                                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                                        <Typography textAlign="center" onClick={() => goRoute(setting)}>{setting}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    )
                                }
                            </Box>

                        </Box>

                        {/* Menu to < 768 */}

                        <Box sx={{ width: '98%', display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', alignItems: 'center' }}>

                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center" onClick={() => goRoute(page)}>{page}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>

                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <Box
                                    component={'img'}
                                    src='https://res.cloudinary.com/dep7wr2dv/image/upload/v1703630590/PublicDataPage/jennytaborda_logo_abn9fu.png'
                                    sx={{ height: 30 }}
                                />
                            </Box>

                            <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                                {
                                    !token && (
                                        <Box>
                                            <Box
                                                sx={{ mr: 3, cursor: 'pointer' }}
                                                onClick={() => navigate('/login')}
                                            >
                                                {
                                                    !token && <Typography>LOGIN</Typography>
                                                }
                                            </Box>
                                        </Box>
                                    )
                                }

                                {
                                    token && (
                                        <>
                                            <Tooltip title="Open settings">
                                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                sx={{ mt: '45px' }}
                                                id="menu-appbar"
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                            >
                                                {settings.map((setting) => (
                                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                                        <Typography textAlign="center" onClick={() => goRoute(setting)}>{setting}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    )
                                }
                            </Box>

                        </Box>

                    </Toolbar>
                </AppBar>

            </HideOnScroll>

            {/* Main */}
            <Box component={'main'}>
                {props.children}
            </Box>

        </Box>
    )
}

export default MainLayout