import { Box } from '@mui/material';
import React from 'react'

const Rating = ({ rating, onRatingChange }) => {
    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <Box
                    component={'span'}
                    key={i}
                    onClick={() => onRatingChange(i)}
                >
                    {/* <svg className={`comfenalco-start-rating ${i <= rating ? 'comfenalco-start-rating-active' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                    </svg> */}

                    <Box 
                        component={'svg'} 
                        // className={`comfenalco-start-rating ${i <= rating ? 'comfenalco-start-rating-active' : ''}`} 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        strokeWidth={1.5} 
                        stroke="currentColor"
                        sx={{
                            width:25,
                            color:`${i <= rating ? 'yellow' : 'white'}`,
                            cursor:'pointer',
                        }}
                    >
                        <Box 
                            component={'path'}
                            strokeLinecap="round"
                            strokeLinejoin="round" 
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" 
                        />
                    </Box>
                </Box>
            );
        }
        return stars;
    };

    return <div className="star-rating">{renderStars()}</div>;
}

export default Rating