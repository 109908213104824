import host from '../settings/ConfigServer' 
const VideoService = {}

const CREATE_TRILLER_TO_SERVER = `${host}/api/video/create`
const DELETE_TRILLER_TO_SERVER = `${host}/api/video/delete`

const UPLOAD_FULLVIDEO_TO_SERVER = `${host}/api/full-video/upload`
const CREATE_FULLVIDEO_TO_SERVER = `${host}/api/full-video/create`
const DELETE_FULLVIDEO_TO_SERVER = `${host}/api/full-video/delete`

const WATCH_VIDEO_API_URL = `${host}/api/video-watch`
const WATCH_VIDEO_FREE_API_URL = `${host}/api/watch-video-free/title`

const BASE_URL = "https://api.cloudinary.com/v1_1"
const cloudName = process.env.REACT_APP_CLOUDINARY_NAME

// Triller
VideoService.uploadTriller = async (video, upload_preset) => {
    const formData = new FormData()

    formData.append('upload_preset', upload_preset);
    formData.append('tags', 'browser_upload'); // Optional - add tags for image admin in Cloudinary
    formData.append('file',video);

    const response = await fetch(`${BASE_URL}/${cloudName}/upload`, {
        method: 'POST',
        body:formData
    });

    if (!response.ok) {
        const resError = await response.json()
        console.log(resError)
        throw new Error(resError.error.message);
    }

    return await response.json();
}

VideoService.createTrillerToServer = async (formData) => {
    const response = await fetch(CREATE_TRILLER_TO_SERVER, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body:JSON.stringify(formData)
    });

    if (!response.ok) {
        const resError = await response.json()
        console.log(resError)
        throw new Error(resError);
    }

    return await response.json();
}

VideoService.deleteTrillerToServer = async (formData) => {
    const response = await fetch(DELETE_TRILLER_TO_SERVER, {
        method: 'DELETE',
        headers: {
            "Content-type": "application/json"
        },
        body:JSON.stringify(formData)
    });

    if (!response.ok) {
        const resError = await response.json()
        console.log(resError)
        throw new Error(resError);
    }

    return await response.json();
}

//FullVideo
VideoService.uploadFullVideo = async (video, upload_preset) => {
    const formData = new FormData()

    formData.append('upload_preset', upload_preset);
    formData.append('tags', 'browser_upload'); // Optional - add tags for image admin in Cloudinary
    formData.append('file',video);

    const response = await fetch(`${UPLOAD_FULLVIDEO_TO_SERVER}`, {
        method: 'POST',
        body:formData
    });

    if (!response.ok) {
        const resError = await response.json()
        console.log(resError)
        throw new Error(resError.error);
    }

    return await response.json();
}

VideoService.createFullVideoToServer = async (formData) => {
    const response = await fetch(CREATE_FULLVIDEO_TO_SERVER, {
        method: 'POST',
        headers: {
            "Content-type": "application/json"
        },
        body:JSON.stringify(formData)
    });

    if (!response.ok) {
        const resError = await response.json()
        console.log(resError)
        throw new Error(resError);
    }

    return await response.json();
}

VideoService.deleteFullVideoToServer = async (formData) => {
    const response = await fetch(DELETE_FULLVIDEO_TO_SERVER, {
        method: 'DELETE',
        headers: {
            "Content-type": "application/json"
        },
        body:JSON.stringify(formData)
    });

    if (!response.ok) {
        const resError = await response.json()
        console.log(resError)
        throw new Error(resError);
    }

    return await response.json();
}

//Watch Vide
VideoService.watchVideo = async (videoId, token) => {
    const response = await fetch(`${WATCH_VIDEO_API_URL}/${videoId}`, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        const resError = await response.json()
        // console.log(resError)
        throw new Error(resError.message);
    }

    return await response.json();
}

VideoService.watchVideoFree = async (videoTitle, token) => {
    const response = await fetch(`${WATCH_VIDEO_FREE_API_URL}/${videoTitle}`, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    });

    if (!response.ok) {
        const resError = await response.json()
        // console.log(resError)
        throw new Error(resError.message);
    }

    return await response.json();
}

export default VideoService