import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import Routes from "./components/routes/Routes";
import { useMemo, useState } from 'react';
import { esES } from '@mui/material/locale';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from './hooks/auth/AuthProvider';

function App() {
    const [mode] = useState('dark');
    // const colorMode = useMemo(
    //     () => ({
    //         toggleColorMode: () => {
    //             setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    //         },
    //     }),
    //     [],
    // );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: grey[900],
                    },
                    secondary: {
                        main: '#EEEEEE',
                    },
                    thirth: {
                        main: '#C6C6C6',
                    },
                    fourth: {
                        main: '#EC0868',
                    },
                    background: {
                        default: grey[100]
                    },
                    error: {
                        main: red.A400,
                    },
                    mode
                },
            }, esES),
        [mode],
    );
    
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <Routes />
                <ToastContainer />
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
