import host from '../settings/ConfigServer' 
const DataVideoService = {}

const CREATE_DATA_VIDEO_API_URL = `${host}/api/video-data/create`
const GET_ALL_DATA_VIDEO_API_URL = `${host}/api/video-data/get-all`
const GET_FREE_DATA_VIDEO_API_URL = `${host}/api/video-fata/get-free`
const GET_FREE_DATA_VIDEO_PUBLIC_API_URL = `${host}/api/video-data/public-videos/get-free`
const GET_FULL_ACCESS_DATA_VIDEO_API_URL = `${host}/api/video-fata/get-fullaccess`

DataVideoService.createDataVideo = async(formData, token, role) => {
    const response = await fetch(CREATE_DATA_VIDEO_API_URL, {
        method: 'POST',
        headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`,
            "role": `${role}`
            // 'Authorization': `Bearer ${storageUtils.getToken()}`
        },
        body:JSON.stringify(formData)
    });

    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.MessageError || error.message);
    }

    return await response.json();
}

DataVideoService.getAllDataVideo = async(token, role) => {
    const response = await fetch(GET_ALL_DATA_VIDEO_API_URL, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`,
            "role": `${role}`
        }
    });

    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.MessageError || error.message);
    }

    return await response.json();
}

DataVideoService.getFreeDataVideo = async () => {
    const response = await fetch(GET_FREE_DATA_VIDEO_API_URL, {
        method: 'GET',
        headers: {
            "Content-type": "application/json"
            // 'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });

    // console.log(response)

    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.MessageError || error.message);
    }

    return await response.json();
}

DataVideoService.getFreeDataVideoPublic = async () => {
    const response = await fetch(GET_FREE_DATA_VIDEO_PUBLIC_API_URL, {
        method: 'GET',
        headers: {
            "Content-type": "application/json"
            // 'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });

    // console.log(response)

    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.MessageError || error.message);
    }

    return await response.json();
}

DataVideoService.getFullAccessDataVideo = async () => {
    const response = await fetch(GET_FULL_ACCESS_DATA_VIDEO_API_URL, {
        method: 'GET',
        headers: {
            "Content-type": "application/json"
            // 'Authorization': `Bearer ${storageUtils.getToken()}`
        }
    });

    // console.log(response)

    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.MessageError || error.message);
    }

    return await response.json();
}

export default DataVideoService